.container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
}

.item {
    width: 100vw;
    height: 100vh;
}

.container::-webkit-scrollbar {
    height: 8px;
}

.container::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}

.container::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
}
