html {
  overflow-y: scroll;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}

html,
body {
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Franie;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  display: block;
}
ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}
.white_A700_1c_white_A700_1c_border {
  border-image: linear-gradient(180deg, #ffffff1c, #ffffff1c) 1;
}
.gray_50_gray_50_00_border {
  border-image: radial-gradient(180deg, #f8f8f8, #f8f8f800) 1;
}
.gray_900_04_gray_900_06_border {
  border-image: linear-gradient(121deg, #191c31, #0f1320) 1;
}

.gsapsection {
  width: 100vw;
  height:100vh;
}

.gsapsectionV {
  width: 100vw;
  height: 100vh;
}
.gsapwrapper {
  display: flex;
  height: 100vh;
  width: 300vw;
  flex-wrap: nowrap;
  /*justify-content: flex-start;*/
  flex-direction: row !important;
}

.gsapwrapperV {
  display: flex;
  height: 400vh;
  width: 100vw;
  flex-wrap: wrap;
  /*justify-content: flex-start;*/
  /*flex-direction: column !important; */
}

.gsapwrapper-combo {
  display: flex;
  height: 100vh;
  width: 100vw;
  /*flex-wrap: nowrap;*/
  /*justify-content: flex-start;*/
  flex-direction: row !important;
    /*overflow: hidden;   */
}

.gsapsection-combo-content {
  /*display: flex;*/
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 60vw;
}

.gsapsection-panel {
  top: 150px;
  position: absolute;
  width: 100%;
  opacity: 0;
}

.gsapring-animation {
  top: 150px;
  right: 100px;
   /* position: relative; */
  /*height: 100vh;*/
}
.gsapring-animation-img {
    position: absolute;
    top: 150px;
    right: 100px;
    opacity: 0;
    /*top: 0;
    left: 0;*/
}

.gsapwrapper-comboleft {
  width: 75vw;
  height:100vh;
  flex-wrap: nowrap;
  flex-direction: row !important;
  overflow: hidden;
}

.gsapsection-comboleft {
    width: 75vw;
    height: 100vh;  
}

.circle-container {
  width: 400px;
  height: 400px;
  transform: rotate(-90deg);
  background: clear;
    position: absolute;
      top: 150px;
      right: 60px;
    }
.circle-container__progress {
  fill: none;
  stroke-linecap: flat;
  stroke: #8CC0DE;;
  stroke-dasharray: 100 100;
  stroke-width: 30px;
  stroke-dashoffset: 0;
   /* For animations... 
  transition: stroke-dashoffset 1s ease-in-out;
  will-change: transform;*/
}

/* in product section */
.product-img-anima {

}

.product-final-section{

}

#scroll-hint {
  display: none;
}

@media only screen and (min-width: 768px) {
  #scroll-hint {
    display: block;
  }
}
.sectionScroller {
  /*scroll-snap-type: y proximity;
  max-height: 100vh;
  overflow-y: scroll;*/
}

.sectionScroller section {
  /*scroll-snap-align: start;
  scroll-snap-stop: always;*/
}

/* canvas {
  position: absolute;
  z-index: 0;
}
height: 100% !important;
*/
/*canvas {
    position: fixed !important;
    width: 100vw;
    height: 25%;
    z-index: 0;
}
*/

.tenants-container {
  position: relative;
  text-align: center;
  overflow: hidden;
  margin: 0;
}

.tenants-middle {
  position: relative;
  margin: 0;/*10rem 0 2rem 0;*/
}

.rec .rec-carousel {
  width: 100vw;
}

.rec .rec-pagination {
  display: flex;

  margin: 2rem -0.6rem 0 0;
}

.rec .rec-item-wrapper {
  display: flex;
  justify-content: space-evenly;
}

.rec .rec-arrow-down,
.rec .rec-arrow-up {
  visibility: hidden;
}

.rec .rec-dot {
  position: relative;
  background-color: white;
  outline: none;
  overflow: hidden;
  border: none;
  transition: all 0.1s ease;
}

.rec .rec-dot_active {
  background-color: black;
  outline: none;
  overflow: hidden;
  border: none;
  transition: all 0.1s ease;
}

/* css for section1b test */

/*#container-hero {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  justify-items: center;
}

#container-hero section:nth-child(even) {
  background-color: #ff6347;
}

#container-hero h1 {
  padding-top: 212px;
}

#container-hero section {
  height: 100vh;
  grid-area: 1 / 1 / 2 / 2;
  background: #f0f0f0;
  clip-path: circle(100% at 50% 50%);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: top;
  width: 100%;
  padding: 0px 7%;
}

.hero {
  height: 100vh;
  display: flex;
  align-items: center;
}

.logotip {
  justify-content: center;
  display: grid;
  padding-top: 33px;
}

.we-are p {
  padding-top: 250px;
  font-family: General Sans;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: justified;
}

.we-are h2 {
  font-family: Barlow Condensed;
  font-size: 110px;
  font-weight: 800;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

figure {
  height: 100%;
  background-color: #212121;
}

.row .col-lg {
  position: relative;
}

.panels {
  position: absolute;
  overflow: hidden;
  height: 280px;
  width: 100%;
  margin-top: 50px;
  left: 0;
  /*   opacity: 0; 
  padding: 50px 0px;
  /*   visibility: hidden; 
}

.panel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212121;
  opacity: 0;
}

.animacija {
  background-color: #212121;
  height: 100vh;
  width: 100% !important;
  padding-top: 150px;
}

.animacija h2 {
  font-family: Barlow;
  font-size: 200px;
  font-weight: 600;
  line-height: 240px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding-left: 7%;
}

.mi-smo h3 {
  font-family: Barlow;
  font-size: 56px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding-top: 40px;
}

.mi-smo p {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  padding-right: 7%;
}

.svg-animacija {
  padding: 100px 0px;
}

.svg-animacija svg {
  max-height: 200px;
  float: right;
}

.logo-rotating {
  float: right;
}

.spacer {
  height: 100vh;
}
*/